<template>
  <div v-if="isVisible" class="overlay" @click="hide">
<!--    <div class="video-container" @click.stop>-->
      <iframe
          class="video-container"
          :src="videoUrl"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
      ></iframe>
<!--    </div>-->
  </div>
</template>

<script setup>
const props = defineProps({
  videoUrl: {
    type: String,
    required: true,
  },
});

const isVisible = ref(false);

function show() {
  isVisible.value = true;
}

function hide() {
  isVisible.value = false;
}

defineExpose({
  show,
  hide
});
</script>


<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}

.video-container {
  width: 90%;
  max-width: 1920px;
  max-height: 1080px;
  aspect-ratio: 16/9;
}

</style>
